function Export(method, url, data, name, isZip,isCsv) {
  const token = localStorage.getItem("playOne-store-token");
  const ajax = new XMLHttpRequest();
  ajax.open(method, url, true);
  ajax.responseType = "blob";
  ajax.setRequestHeader("Content-Type", "application/json");
  ajax.setRequestHeader("Authorization", token);
  ajax.onload = () => {
    const data = ajax.response;
    const blob = new Blob([data], {
      type: "application/octet-stream",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    if (isZip) {
      a.download = name + ".zip";
    } else {
      if(isCsv){
        a.download = name + ".csv";
      }else{
        a.download = name + ".xls";
      }
    }
    a.href = url;
    a.click();
  };
  ajax.send(JSON.stringify(data));
}
export default Export;
